.page-template-page-qsn{
  #block-banner{
    &:after{
      content:url('./src/img/oval-banner.png');
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 543px;
      height: 167px;
    }
  }

  @include media-breakpoint-down(lg){
    #block-banner{
      &:after{
        left:-130px;
      }
    }
  }
  @include media-breakpoint-down(md){
    #block-banner{
      &:after{
        display: none;
      }
    }
  }
}
#qsn-pres{
  background-color: $bloc-bg;
  padding-top: 81px;
  padding-bottom:64px;
  background-image: url('./src/img/oval_qsn_pres.png');
  background-repeat: no-repeat;
  background-position-x: -4px;
  background-size: auto 100%;
  .qsn-pres-wrapper{
    .qsn-text{
      max-width: 555px;
      margin-top: 29px;
    }
    .btn{
      margin-top: 55px;
    }
  }
  @include media-breakpoint-down(lg){
    background-position-x:-130px;
    padding-top: 42px;
    padding-bottom: 57px;
  }
  @include media-breakpoint-down(md){
    background-image:none;
  }
}
#qsn-four{
  background-color: $bloc-bg;
  padding-top: 118px;
  padding-bottom: 109px;
  background-image: url('./src/img/qsn.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: auto 100%;
  .qsn-four-text{
    margin-top: 25px;
    max-width: 480px;
  }
  @include media-breakpoint-down(lg){
    background-position-x: calc(100% + 130px);
  }

  @include media-breakpoint-down(md){
    background-image:none;
  }
}
#qsn-services {
  background-color: $blue;
  padding-top: 89px;
  padding-bottom: 60px;
  background-image: url('./src/img/bg_oval_slider_services.png');
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  .slider-services-title {
    text-align: center;
  }
  .slick-track {
    display: flex;
  }
  .slides-and-arrows {
    position: relative;
    .slider-arrow {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      &.prev-arrow {
        left: -75px;
      }
      &.next-arrow {
        right: -75px;
      }
    }
  }
  .slider-services-wrapper {
    margin-top: 39px;
    .slide-services-single {
      height: auto;
      &:focus {
        outline: 0;
      }
      .slide-single-wrapper {
        max-width: 360px;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        height: 100%;
        margin: 0 auto;
        .slide-services-up {
          position: relative;
          .service-img-wide {
            width: 100%;
            height: auto;
          }
          .service-picto {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            -o-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
          }
        }
        .slide-services-bottom {
          background-color: white;
          padding: 42px 38px 31px 38px;
          flex: 1 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .service-title {
            font-family: $font-title;
            color: $blue;
            font-size: 18px;
            font-weight: 700;
          }
          .service-content {
            margin-top: 15px;
            font-size: 14px;
            font-weight: 400;
            color: $gray-fourth;
          }
          .btn {
            margin-top: 45px;
          }
        }
      }
    }
  }
  .slider-services-btn {
    margin-top: 58px;
    text-align: center;
  }
  @media (max-width: 1300px) {
    .slides-and-arrows {
      .slider-arrow {
        &.next-arrow {
          right: -25px;
        }
        &.prev-arrow {
          left: -25px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .slider-services-wrapper {
      .slide-services-single {
        .slide-single-wrapper {
        }
      }
    }
    .slides-and-arrows {
      .slider-arrow {
        &.next-arrow {
          right: 5px;
        }
        &.prev-arrow {
          left: 5px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .slider-services-wrapper {
      .slide-services-single {
        .slide-single-wrapper {
          max-width: 310px;
          .slide-services-bottom {
            padding: 42px 15px 31px 15px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .slides-and-arrows {
      .services-slider-arrows{
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .slider-arrow {
          position: static;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none;
        }
      }
    }
  }
}