#delivery-intro{
  height: calc(100vh - 81px);
  background-color: $yellow;
  background-image:url('./src/img/oval_delivery.png');
  background-size:contain;
  background-position: right;
  background-repeat: no-repeat;
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .row{
      width: 100%;
    }
  }
  .intro-text{
    color: $blue;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    p{
      margin-top: 35px;
    }
  }
  .img-banner{
    position: absolute;
    top: 60%;
    right: -336px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .scroll-down{
    text-align: center;
    position: absolute;
    left: 50%;
    bottom:20px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

  }
  @include media-breakpoint-down(md){
    background-image:none;
    .img-banner{
      display: none;
    }
  }
}
#delivery-delivery{
  padding-top: 106px;
  padding-bottom:100px;
  background-color:$bloc-bg;
  background-image:url('./src/img/plat.png');
  background-repeat: no-repeat;
  background-position-x: calc(100% + 150px);
  background-size:auto 100%;
  .text-content{
    margin-top: 25px;
  }
  @media (max-width: 1600px){
    padding-top: 85px;
    padding-bottom: 85px;
    background-position-x: calc(100% + 300px);
  }
  @include media-breakpoint-down(lg){
    background-position-x: calc(100% + 400px);
  }
  @include media-breakpoint-down(md){
    background-image: none;
  }
}
#delivery-pictos{
  padding-top: 100px;
  padding-bottom: 110px;
  .main-title-blue{
    text-align: center;
  }
}
#warm-up-section{
  padding-top: 106px;
  padding-bottom:100px;
  background-color:$bloc-bg;
  background-image:url('./src/img/warm_up_bg.png');
  background-repeat: no-repeat;
  background-position-x: -200px;
  background-size:auto 100%;
  .text-content{
    margin-top: 25px;
  }
  @media (max-width:1690px){
    background-position-x: -380px;
  }

  @media (max-width:1339px){
    background-position-x: -480px;
  }
  @include media-breakpoint-down(lg){
    padding-top: 67px;
    padding-bottom: 57px;
    background-position-x: -650px;
  }
  @include media-breakpoint-down(md){
    background-image: none;
  }
}
#delivery-etablissements{
  margin-top: -172px;
}