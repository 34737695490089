/*
Fonts*/
$font-main : 'Lato', sans-serif;
$font-title : 'Asap', sans-serif;

/*
Colors*/
$black:#4a4a4a;
$gray:#4a494b;
$gray-second:#797979;
$text-color: $gray;
$blue: #003d6b;
$yellow:#f9d219;
$bloc-bg: #fbfbfb;
$gray-third:#9b9b9b;
$gray-fourth:#747474;