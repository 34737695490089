#seek-list{
  .seek-list-text{
    padding-top: 79px;
    padding-bottom: 79px;
    max-width:740px;
    margin: 0 auto;
    text-align: center;
    .seek-list-text-content{
      margin-top: 25px;
    }
  }
  .seek-list-search{
    select{
      background-color: $blue;
      border-radius: 5px;
      border: 1px solid #ffffff;
      color:white;
      text-transform: uppercase;
      padding: 16px 16px 16px 23px;
      width: 100%;
      font-family: $font-title;
      cursor: pointer;
      option{
        color:black;
        background-color: white;
      }
      &:focus{
        outline:0;
        box-shadow:0;
      }
    }
    .select2-container{
      margin-top: 0;
    }
    #seek-etablissement{
      position: relative;
      .zipcode{
        border-radius: 25px;
        border: 1px solid #dfdfdf;
        width: 100%;
        padding: 10px 30px;
        background-repeat:no-repeat;
        background-position: 96% center;
        &:focus{
          outline:0;
        }
      }
      .submit-btn{
        position: absolute;
        border:0;
        top:50%;
        right:15px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
  .seek-list-result-wrapper{
    margin-top: 30px;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.2);
    border-radius: 0 6px 6px 0;
    background-color: #ffffff;
    padding-left: 59px;
    padding-right: 59px;
    .list-wrapper{
      .list-head{
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        div{
          padding: 25px 0px;
          font-size: 16px;
          font-weight: 400;
          color: #9b9b9b;
        }
        .list-head-title{
          flex:1 1 30%;
          max-width: 30%;
        }
        .list-head-address{
           flex:1 1 33%;
           max-width: 33%;
        }
        .list-head-phone{
          flex:1 1 37%;
          max-width: 37%;
        }
      }
      .list-list{
        max-height: 480px;
        overflow:hidden;
        .list-list-single{
          border-top: 1px solid #dfdfdf;
          display: flex;
          align-items: center;
          div{
            padding: 25px 0px;
            font-size: 14px;
            font-weight: 600;
            color:$blue;
            font-family: $font-title;
          }
          .list-list-title{
            flex:1 1 30%;
            max-width: 30%;
            font-size: 16px;
          }
          .list-list-address{
            flex:1 1 33%;
            max-width: 33%;
            padding-left: 35px;
            position: relative;
            &:before{
              content:"";
              width: 25px;
              height: 31px;
              background-size: cover;
              background-image: url('./src/img/picto_list.png');
              position: absolute;
              left:0;
              top:50%;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
          .list-list-phone{
            flex:1 1 18.5%;
            max-width: 18.5%;
          }
          .list-list-link{
            flex:1 1 18.5%;
            max-width: 18.5%;
            text-align: right;
            a {
              &:hover {
                box-shadow: none;
              }
            }
          }
          &:first-child{
            border-top: 0;
          }
        }
      }
    }
    .mCSB_scrollTools{
      .mCSB_dragger{
        .mCSB_dragger_bar{
          border-radius: 4px;
          border: 1px solid $blue;
          background-color: $blue;
          width: 8px;
        }
      }
      .mCSB_draggerRail{
        border-radius: 4px;
        border: 1px solid #dfdfdf;
        background-color: #fbfbfb;
        width: 8px;
      }
    }
  }
  @include media-breakpoint-down(md){
    .seek-list-result-wrapper{
      padding-left: 15px;
      padding-right: 15px;
      .list-wrapper{
        .list-head{
          display: none;
          flex-direction: column;
          .list-head-title, .list-head-address, .list-head-phone{
            flex:1 1 100%;
            max-width: 100%;
          }
        }
        .list-list{
          .list-list-single{
            flex-wrap: wrap;
            .list-list-title, .list-list-address, .list-list-phone, .list-list-link{
              flex: 1 1 50%;
              max-width: 50%;
              text-align: left;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm){
    .seek-list-search-col{
      margin-top: 30px;
    }
    .seek-list-result-wrapper{
      padding-left: 15px;
      padding-right: 15px;
      .list-wrapper{
        .list-head{
          display: none;
          flex-direction: column;
          .list-head-title, .list-head-address, .list-head-phone{
            flex:1 1 100%;
            max-width: 100%;
          }
        }
        .list-list{
          .list-list-single{
            flex-wrap: wrap;
            .list-list-title, .list-list-address, .list-list-phone, .list-list-link{
              flex: 1 1 50%;
              max-width: 50%;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xs){
    .seek-list-result-wrapper{
      .list-wrapper{
        .list-list{
          .list-list-single{
            flex-wrap: wrap;
            .list-list-title, .list-list-address, .list-list-phone, .list-list-link{
              flex: 1 1 100%;
              max-width: 100%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
#seek-seek-etablissements{
  margin-top: 105px;
}