#contact-intro{
  background-color: $yellow;
  position:relative;
  padding: 150px 0;
  background-image: url('./src/img/contact_oval.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 50% 100%;
  .row{
    align-items: center;
  }
  .main-title-blue{
    font-family: $font-title;
    font-style: italic;
    font-size: 60px;
    font-weight: 500;
  }
  #breadcrumbs{
    position: absolute;
    top:15px;
    left: calc(50% - 522px);
    z-index:10;
  }
  .contact-intro-content{
    color:$blue;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 30px;
  }
  .form-bottom{
    text-align: right;
  }
  .submit-wrapper{
    input{
      background-image: url('./src/img/paper_plane.png');
      background-position: 15% center;
      padding-right: 20px;
      padding-left: 50px;
    }
  }

  @media (max-width:1439px){
    #breadcrumbs{
      left:calc(50% - 423px);
    }
  }
  @include media-breakpoint-down(lg){
    #breadcrumbs{
      top:45px;
      left:25px;
    }
  }
  @include media-breakpoint-down(md){
    background-image: none;
  }
}
#contact-recrut{
  padding:90px 0;
  background-image:url('./src/img/bg_recrutement.jpg');
  background-size: cover;
  text-align: center;
  .contact-recrut-content{
    color:$blue;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.08px;
    margin-top:25px;
  }
}