/*!
Theme Name: Adveris Starter Theme
Author: Eddy SAMY
Description: Starter Theme with Bootstrap to use with Timber
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: Adveris
Tags:

*/


/* ==========================================================================
   Bootstrap
   ========================================================================== */
@import "../../node_modules/bootstrap/scss/bootstrap";

/* ==========================================================================
   Layout
   ========================================================================== */
@import "layout/variables";
@import "layout/header";
@import "layout/content";
@import "layout/footer";
@import "layout/homepage";
@import "layout/qsn";
@import "layout/offers";
@import "layout/seek-establishment";
@import "layout/delivery-advice";
@import "layout/list-espace-pro";
@import "layout/espace-pro";
@import "layout/services";
@import "layout/single";
@import "layout/list-articles";
@import "layout/single-etablissement";
@import "layout/contact";
@import "layout/search";