#search-results{
  min-height: 500px;
  padding-top: 50px;
  .result-single{
    margin-top: 15px;
    a{
      color:$blue;
      &:hover{color: $blue;}
    }
  }
}