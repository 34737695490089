#espace-pro-list{
  margin-top: -25px;
  padding-bottom: 80px;
  .espace-col{
    margin-top: 30px;
    &:first-child, &:nth-child(2){
      margin-top: 0;
    }
  }
  .espace-wrapper{
    display: block;
    border-radius: 6px;
    position: relative;
    .overlay {
      background-color: $blue;
      position: absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }
    .espace-list-img{
      width: 100%;
      height: auto;
      border-radius: 6px;
    }
    .espace-content-wrapper{
      padding-left: 34px;
      padding-right: 34px;
      position: absolute;
      top:50%;
      left:0;
      right: 0;
      text-align: center;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .espace-title{
      color:white;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .espace-content{
      text-align: justify;
      color:white;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -ms-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content:flex-start;
      > div{
        margin: 5px;
      }

    }
    &:hover{
      .espace-content{
        max-height: 150px;
      }
      .overlay{
        opacity: 0.8;
      }
    }
  }
  @include media-breakpoint-down(md){
    .espace-col{
      &:nth-child(2){
      }
      .espace-content-wrapper{
        .espace-content{
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm){
    .espace-col {
      &:nth-child(2) {
        margin-top: 30px;
      }
    }
    .espace-wrapper{
      height: 250px;
      overflow: hidden;
      .espace-list-img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        height: 250px;
        width: auto;
      }
    }
  }
}