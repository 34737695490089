.article-banner{
  position: relative;
  #breadcrumbs{
    position: absolute;
    top:15px;
    left: calc(50% - 522px)
  }
  img{
    width: 100%;
    height:auto;
  }
  @media (max-width:1439px){
    #breadcrumbs{
      left:calc(50% - 423px);
    }
  }
  @include media-breakpoint-down(lg){
    #breadcrumbs{
      top:45px;
      left:25px;
    }
  }

}
.article-content{
  background-color: $bloc-bg;
  .container{
    border-radius: 6px;
    position: relative;
    top:-88px;
    background-color: white;
    padding-bottom: 53px;
    z-index: 2;
    .article-content-wrapper{
      max-width: 750px;
      margin: 0 auto;
      .article-date{
        width: 165px;
        display: inline-block;
        text-align: center;
        margin-top: -51px;
        .article-date-up{
          background-color: $blue;
          color:white;
          padding:9px 0px;
          border-radius: 6px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          img{
            display: inline-block;
            vertical-align: middle;
          }
          span{
            font-size: 16px;
            font-weight: 600;
            font-family: $font-title;
          }
        }
        .article-date-bottom{
          border:8px solid $blue;
          color:$blue;
          border-radius: 6px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          font-weight: 700;
        }
      }
      .main-title-blue{
        margin-top: 56px;
      }
      .article-content-content{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-top:35px;
        color:$gray;
        img{
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
  @include media-breakpoint-down(sm){
    .container{
      top:0;
    }
  }
}
#single-slider-news{
  position: relative;
  top: -190px;
  z-index: 1;
  .slider-news{
    padding-top:163px;
  }
  @include media-breakpoint-down(sm){
    .container{
      padding-top: 200px;
    }
  }
}
body.single-post{
  footer{
    margin-top: -190px;
  }
}