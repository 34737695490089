.page-templates-page-services{
  #block-banner{
    .banner-right{
      img{
        max-width: 400px;
      }
    }
  }
}
#service-list-list{
  padding-top:78px;
  padding-bottom: 70px;
  .service-list-list-title{
    text-align: center;
    .text-content{
      margin-top: 25px;
      max-width: 738px;
      display: inline-block;
    }
  }
  .services-list{
    margin-top: 50px;
    justify-content: center;
  }
  .service-single{
    margin-top: 30px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow:hidden;
    height: calc(100% - 30px);
    display: flex;
    flex-flow: column;
    .service-single-up{
      position:relative;
      .service-img-wide{
        width:100%;
        height: auto;
      }
      .service-picto{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .service-single-bottom{
      background-color: white;
      padding: 42px 38px 31px 38px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .service-title{
        font-family: $font-title;
        color:$blue;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
      .service-content{
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: $gray-second;
      }
      .btn{
        margin-top: 45px;
      }
    }
  }
}