#list-articles-banner{
  height: calc(100vh - 81px);
  background-color: $yellow;
  background-size:contain;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  background-image: url('./src/img/oval-list-article.png');
  background-position-x: right;
  background-repeat: no-repeat;
  background-size:649px 100%;
  .article-list-img-first{
    position: absolute;
    top:-271px;
    left:-200px;
  }
  .article-list-img-second{
    position: absolute;
    right:0;
    top:0;
  }
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index:9;
    .row{
      width: 100%;
    }
    .big-title-blue{
      margin-top: 100px;
    }
    .intro-text{
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color:$gray;
    }
  }
  @include media-breakpoint-down(lg){
    .article-list-img-second{
      width: 400px;
    }
    background-image: none;
  }
  @include media-breakpoint-down(sm){
    .article-list-img-second{
      display: none;
    }
  }
}
#list-articles-list{
  background-color: $bloc-bg;
  padding-bottom: 51px;
  > .container{
    margin-top: -115px;
  }
  .list-articles-col{
    .article-date{
      display: none;
    }
  }
  .article-single-wrapper{
    margin-top: 30px;
    background-color: white;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    &:first-child{
      margin-top: 0;
    }
    .article-single-up{
      position: relative;
      img{
        width: 100%;
        height:auto;
      }
      .article-single-categories{
        position: absolute;
        bottom: 15px;
        left:58px;
        .article-cat{
          border-radius: 30px;
          background-color: rgba(255,255,255,0.2);
          color: white;
          display: inline-block;
          padding:9px 52px;
          margin-left: 15px;
          font-size: 16px;
          font-weight: 500;
          font-family: $font-title;
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
    .article-single-bottom{
      padding:31px 58px;
      .article-title{
        font-size: 16px;
        font-weight: 700;
        color: #4a4a4a;
        letter-spacing: 2px;
        font-family: $font-title;
      }
      .article-excerpt{
        color: #4a494b;
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
      }
      .article-link{
        font-size: 16px;
        font-weight: 500;
        color:$blue;
        display: inline-block;
        margin-top: 35px;
        font-family: $font-title;
        &:hover{
          text-decoration: none;
        }
        &:after{
          content:url('./src/img/arrow-link-right.png');
          display: inline-block;
          vertical-align: middle;
          margin-left:10px;
        }
      }
    }
  }
  .article-date{
    width: 165px;
    display: inline-block;
    text-align: center;
    .article-date-up{
      background-color: $blue;
      color:white;
      padding:9px 0px;
      border-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      img{
        display: inline-block;
        vertical-align: middle;
      }
      span{
        font-size: 16px;
        font-weight: 600;
        font-family: $font-title;
      }
    }
    .article-date-bottom{
      border:8px solid $blue;
      color:$blue;
      border-radius: 6px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      font-weight: 700;
      position: relative;
      background-color: white;
      &:after{
        position: absolute;
        content:"";
        height: 100%;
        width: 8px;
        right:-8px;
        background-color: white;
        top:0;
      }
      &:before{
        position: absolute;
        content:"";
        height: 11px;
        width: 11px;
        right:-13px;
        background-color: white;
        top:50%;
        -webkit-transform: translateY(-50%) rotate(45deg);
        -moz-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
        -o-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  @include media-breakpoint-down(sm){
    .list-articles-col{
      margin-top: 15px;
      .article-date{
        display: block;
        width: 100%;
        margin-bottom: 15px;
        .article-date-bottom{
          &:before,&:after{display: none;}
        }
      }
    }
    .col-date{
      text-align: center;
      display: none;
    }

  }
  @include media-breakpoint-down(xs){
    > .container{
      margin-top: -25px;
    }
  }
}
.spinner{
  text-align: center;
  background-color: $bloc-bg;
  padding-bottom: 60px;
  img{
    -webkit-animation:rotating 1.7s linear infinite;
    -o-animation:rotating 1.7s linear infinite;
    animation:rotating 1.7s linear infinite;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    &.appear{
      opacity: 1;
    }
  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}