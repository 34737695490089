#intro-section{
  height: calc(100vh - 81px);
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .row{
      width: 100%;
    }
  }
  .intro-text{
    color: white;
    text-align: center;
    h1,h2,h3,h4,h5{
      font-size: 44px;
      font-weight: 600;
      font-style: italic;
      line-height: 50px;
      letter-spacing: 3px;
    }
    p{
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      /* Text style for "Lorem ipsu" */
      letter-spacing: 1.08px;
    }
  }
  .bottom-wrapper{
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 2;
    right: 15px;
    .logo-service{
      position: relative;
      top:-25px;
    }
    .scroll-down{
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 10px;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .oval-effect{
    position: absolute;
    right: -477px;
    bottom: -799px;
  }
  @include media-breakpoint-down(lg){
    &.parallax-wrapper{
      > div{
        &:first-child{
          top:0;
          height: 100%;
          background-size: cover;
          background-position-y: center;
          background-position: center;
          -webkit-transform: none !important;
          -moz-transform: none !important;
          -ms-transform: none !important;
          -o-transform: none !important;
          transform: none !important;
        }
      }
    }
    .oval-effect{
      right: -643px;
    }
  }
  @include media-breakpoint-down(sm){
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    .oval-effect{
      display: none;
    }
    .intro-text{
      h1,h2,h3,h4,h5{
        font-size: 24px;
        line-height: 35px;
      }
      p{
        font-size: 14px;
        margin-top: 25px;
        margin-bottom: 50px;
      }
    }
    .bottom-wrapper{
      bottom: -42px;
      .logo-service{
        width: 43px;
      }
    }
  }
}

#home-livraison{
  padding-top: 44px;
  padding-bottom: 44px;
  background-image: url("./src/img/bg-livraison.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% + 151px);
  background-size: auto 100%;
  .livraison-text{
    margin-top: 30px;
    line-height: 19px;
    color:$gray;
    ul{
      line-height: 30px;
      li{
        list-style-type: none;
        &:before{
          content:url('./src/img/bullet_yellow.svg');
          width: 17px;
          height: 17px;
          display: inline-block;
          position: relative;
          left:-15px;
          vertical-align: middle;
          top:-5px;
        }
      }
    }
  }
  .btn{
    margin-top: 25px;
  }
  @include media-breakpoint-down(lg){
    background-position-x: calc(100% + 316px);
  }
  @include media-breakpoint-down(md){
    background:none;
  }
}
#home-offers {
  text-align: center;
  padding-top: 85px;
  padding-bottom: 77px;
  background-color: $blue;
  > div {
    &:first-child {
      top: -25%;
      background-size: 100%;
    }
  }
  .offers-row {
    margin-top: 44px;
    justify-content: center;
    .offers-col {
      .offer-wrapper {
        box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        height: 419px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 50%;
          right: 0;
          bottom: 0;
          height: 100%;
          width: auto;
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
          transform-origin: center left;
        }
        .offer-single-title {
          color: white;
          z-index: 2;
          position: relative;
          font-size: 30px;
          font-weight: 600;
        }
        .btn {
          z-index: 2;
          margin-top: 27px;
        }
        &:before {
          position: absolute;
          top: 30px;
          right: 30px;
          bottom: 30px;
          left: 30px;
          border: 1px solid #fff;
          content: '';
          z-index: 1;
          opacity: 0;
          -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
          transition: opacity 0.35s, transform 0.35s;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        &:hover {
          &:before {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          img {
            -webkit-transform: scale(1.3) translateX(-50%);
            -moz-transform: scale(1.3) translateX(-50%);
            -ms-transform: scale(1.3) translateX(-50%);
            -o-transform: scale(1.3) translateX(-50%);
            transform: scale(1.3) translateX(-50%);
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .offers-row{
      .offers-col{
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        .offer-wrapper {

        }
      }
    }
  }
}
#home-etablissements{
  .establishment-seek{
  }
}
#home-pro{
  background-color: $blue;
  background-image:url('./src/img/bg_espace_pro.jpg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 922px 500px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  .overlay-round{
    background-color: $blue;
    width: 246px;
    height: 109%;
    position: absolute;
    top: 50%;
    right: 812px;
    border-radius: 100%;
    transform: translateY(-50%);
    z-index: 1;
    .overlay-single{
      position: absolute;
      left:100%;
      color:white;
      min-width: 850px;
      &.position-1{
        left: calc(100% - 57px);
        top:50px;
      }
      &.position-2{
        left: calc(100% - 23px);
        top:150px;
      }
      &.position-3{
        left: calc(100% - 13px);
        top:250px;
      }
      &.position-4{
        left: calc(100% - 23px);
        bottom:150px;
      }
      &.position-5{
        left: calc(100% - 57px);
        bottom:50px;
      }
      .single-title-wrapper{
        transform-origin: left;
        display: inline-block;
        &:before{
          content: "";
          width: 26px;
          height: 26px;
          background-color: white;
          border-radius: 100%;
          display: inline-block;
          vertical-align: middle;
          margin-right: 18px;
          transform-origin: center;
          -webkit-transition: all 0.1s;
          transition: all 0.1s;
          -webkit-transform: scale3d(0.3,0.3,0.3);
          -moz-transform: scale3d(0.3,0.3,0.3);
          -ms-transform: scale3d(0.3,0.3,0.3);
          -o-transform: scale3d(0.3,0.3,0.3);
          transform: scale3d(0.3,0.3,0.3);
        }
      }
      .single-title{
        vertical-align: middle;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.39px;
        display: inline-block;
        color:white;
        transform-origin: left;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        &:hover{
          text-decoration: none;
        }
      }
      .btn-pro{
        margin-left: 50px;
        opacity: 0;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-transform: translateX(50px);
        -moz-transform: translateX(50px);
        -ms-transform: translateX(50px);
        -o-transform: translateX(50px);
        transform: translateX(50px);
        &:before{
          content: url('./src/img/arrow_right_blue.svg');
          display: inline-block;
        }
      }
      &:hover{
        .single-title-wrapper{
          .single-title{
            font-size: 26px;
          }
          &:before{
            -webkit-transform: scale3d(1,1,1);
            -moz-transform: scale3d(1,1,1);
            -ms-transform: scale3d(1,1,1);
            -o-transform: scale3d(1,1,1);
            transform: scale3d(1,1,1);
            background-color: #f9d219;
          }
        }
        .btn-pro{
          opacity: 1;
          -webkit-transform: translateX(0px);
          -moz-transform: translateX(0px);
          -ms-transform: translateX(0px);
          -o-transform: translateX(0px);
          transform: translateX(0px);
        }
      }
    }
  }
  .btn{
    margin-top: 53px;
  }
  .container{
    .row{
      height: 500px;
      position: relative;
      align-items: center;
    }
  }
  .home-pro-left{
    position: relative;
    z-index: 2;
  }
  .home-pro-right{
    display: none;
    .list-wrapper{
      .list-link{
        padding: 15px 0;
        a{
          color:white;
          display: inline-block;
          &:before{
            content: "";
            width: 10px;
            height: 10px;
            background-color: $yellow;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 18px;
          }
          &:after{
            content: url('./src/img/arrow_right_blue.svg');
            display: inline-block;
            padding: 0px 19px;
            margin-left: 15px;
            border-radius: 100px;
            background-color: white;
          }
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg){
    background-size: cover;
    .home-pro-right{
      display: block;
    }
    .overlay-round{
      display: none;
    }
  }
  @include media-breakpoint-down(md){
    background-position: center;
    .row-pro-list{
      justify-content: center;
    }
  }
  @include media-breakpoint-down(sm){
    .container{
      .row{
        height: auto;
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
    .home-pro-right{
      margin-top: 25px;
      .list-wrapper{
        text-align: center;
        .list-link{
          a{
            &:before{display: none;}
            &:after{
              display: block;
              width: 60px;
              margin-top: 11px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
#home-services{
  padding-top: 88px;
  padding-bottom: 78px;
  .main-title-blue{
    text-align: center;
  }
  .row-list-services{
    margin-top:65px;
    justify-content: space-around;
    .service-single-wrapper{
      text-align: center;
      img{
        max-width: 100%;
      }
      p{
        margin-top: 15px;
        color:$gray-second;
        font-family: $font-title;
        font-style: italic;
        font-weight: 500;
        line-height: 1em;
      }
    }
  }
  .btn-wrapper{
    text-align: center;
    margin-top: 48px;
  }
  @include media-breakpoint-down(md){
    .row-list-services{
      .service-single-wrapper{
        p{
          font-size: 15px;
        }
      }
    }
  }
}