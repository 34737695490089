html{
  margin-top: 0px !important;
}
body{
  margin-top: 81px;
  font-family: $font-main;
  overflow-x: hidden;
}
h1,h2,h3,h4,h5{
  font-family: $font-title;
}
p{
  a{
    color:$blue;
    &:hover{
      color:$blue;
    }
  }
}
main#content{
  overflow: hidden;
}
.hidden{
  visibility: hidden;
}
.container-custom{
  @include media-breakpoint-up(lg){
    max-width: 1200px;
  }
  @media (min-width: 1440px) {
    max-width: 1440px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @include make-container();
  @extend .container;
}
.parallax-wrapper{
  position: relative;
  overflow: hidden;
  > div{
    &:first-child{
      height: 200%;
      position: absolute;
      top: -50%;
      background-size: 125%;
      background-repeat: no-repeat;
      background-position-y: 100%;
      left: 0;
      right: 0;
    }
  }
}
/* Titres */
.main-title-blue{
  font-size: 40px;
  line-height: 45px;
  color: $blue;
  font-weight: 400;
}
.title-white{
  font-size: 40px;
  font-weight: 500;
  color:white;
}
.main-title-white{
  font-size: 60px;
  font-weight: 500;
  font-style: italic;
  color:white;
  @include media-breakpoint-down(md){
    font-size: 38px;
  }
  @include media-breakpoint-down(sm){
    font-size: 24px;
  }
}
.big-title-blue{
  font-size: 60px;
  font-weight: 500;
  font-style: italic;
  color:$blue;
  @include media-breakpoint-down(md){
    font-size: 40px;
  }
}
.small-title-blue{
  color:$blue;
  font-size: 24px;
  font-weight: 400;
}
/* Textes */
.text-content{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.08px;
  color: #4a494b;
  line-height: 1.2em;
}
.stylize-text{
  ul{
    padding-left: 0;
    li{
      padding-left: 30px;
      position: relative;
      list-style-type: none;
      margin-bottom: 10px;
      &:before{
        content:url('./src/img/arrow-liste.png');
        position: absolute;
        top:3px;
        left:0;
      }
    }
  }
}
/* Boutons */
.btn{
  background-color: $blue;
  border-radius: 150px;
  display: inline-block;
  padding: 15px 26px;
  line-height: 1px;
  position: relative;
  overflow: hidden;
  border:0;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  span{
    color:white;
    font-size: 12px;
    font-weight: bold;
    line-height: 1em;
    text-transform: uppercase;
  }
  &.min-width{
    min-width: 160px;
  }
  &:focus{
    box-shadow: none;
  }
  &:before{
    //position: absolute;
    //top: 0;
    //right: 100%;
    //width: 100%;
    //height: 100%;
    //background: rgba(255,255,255,0.5);
    //content: '';
    //-webkit-transition: all 0.3s;
    //transition: all 0.3s;
    //-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
    //transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
  }
  &:after{
    content:url('./src/img/arrow_right.svg');
    display: inline-block;
    width: 15px;
    height: auto;
    margin-left: 15px;
    vertical-align: middle;
  }
  &:hover{
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.5);
    &:before{
      //right:-100%;
      //-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
      //transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
    }
  }
  &.btn-white{
    background-color: white;
    span{
      color:$blue;
    }
    &:after{
      content: url('./src/img/arrow_right_blue.svg');
    }
    &:before{
      background: rgba(0,61,107,0.2);
    }
  }
  &.btn-download{
    &:after{
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg); 
    }
  }
  @include media-breakpoint-down(xs){
    &.btn-download{
        padding: 15px 17px;
        &:after{
          margin-left: 5px;
        }
      }
    }
}
.btn-pro{
  display: inline-block;
  padding: 9px 24px;
  background-color: white;
  border-radius: 100px;
  &:before{
    content: url('./src/img/arrow_right_blue.svg');
    display: inline-block;
  }
}

/* Fil d'Arianne*/
#breadcrumbs{
  font-size: 12px;
  font-weight: 400;
  a, span{
    color:white;
  }
}

/* Liste de pictogrammes */
#list-picto{
  padding:60px 0;
  .row-etablissements-pictos{
    justify-content: space-between;
    .etablissements-pictos-wrapper{
      text-align: center;
      img{
        max-width: 100%;
      }
      p{
        color:$blue;
        line-height: 1.2em;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 16px;
        position: relative;
        &:after{
          content:"";
          position: absolute;
          bottom: 0;
          width: 34px;
          height: 2px;
          background-color: $yellow;
          left:50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
      .picto-sub-txt{
        font-size: 14px;
        font-weight: 400;
        /* Text style for "Lorem ipsu" */
        letter-spacing: 0.95px;
        color:$gray;
      }
    }
  }
}
/* Slider des actualités*/
.slider-news{
  padding-top: 86px;
  padding-bottom: 77px;
  background-color: $yellow;
  .slick-track{
    display: flex;
    .slick-slide{
      height: unset;
    }
  }
  .slider-and-arrows{
    position: relative;
  }
  .slider-news-title{
    text-align: center;
  }
  .slider-news-wrapper{
    margin-top: 60px;
    .slick-track{
      padding: 15px 0 !important;
    }
    .slide-news-single{
      &:focus{
        outline:0;
      }
      .slide-new-wrapper{
        height: 100%;
        max-width: 356px;
        margin: 0 auto;
        background-color: white;
        border-radius: 9px;
        overflow: hidden;
        box-shadow: 1px 10px 16px rgba(0, 0, 0, 0.05);
        img{
          width: 100%;
          height: auto;
        }
        .news-content{
          padding: 23px;
          .news-title{
            font-size: 16px;
            font-weight: 700;
          }
          .news-excerpt{
            font-size: 14px;
            font-weight: 400;
            color:$gray-second;
          }
          a{
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            color:$blue;
            margin-top: 30px;
            &:hover{
              text-decoration: none;
              &:after{
                -webkit-transform: translateX(10px);
                -moz-transform: translateX(10px);
                -ms-transform: translateX(10px);
                -o-transform: translateX(10px);
                transform: translateX(10px);
              }
            }
            &:after{
              -webkit-transition: all 0.2s;
              -moz-transition: all 0.2s;
              -ms-transition: all 0.2s;
              -o-transition: all 0.2s;
              transition: all 0.2s;
              content: url('./src/img/arrow_right_blue.svg');
              display: inline-block;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .news-slider-arrows{
    .slider-arrow{
      position: absolute;
      top:50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      &.prev-arrow{
        left:-60px;
      }
      &.next-arrow{
        right:-60px;
      }
    }
  }
  @media (max-width: 1300px){
    .news-slider-arrows{
      .slider-arrow{
        &.prev-arrow{
          left:-35px;
        }
        &.next-arrow{
          right:-35px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg){
    .slider-news-wrapper{
      .slide-news-single{
        .slide-new-wrapper{
          max-width: 290px;
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .slider-news-wrapper{
      .slide-news-single{
        .slide-new-wrapper{
          max-width: 220px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm){
    .news-slider-arrows{
      display: none;
    }
     .slider-news-wrapper{
       .slide-news-single{
         .slide-new-wrapper{
           max-width: 320px;
         }
       }
     }
   }
}

/* Formulaires */
form.wpcf7-form{
  .input-wrapper{
    margin-top: 25px;
    .wpcf7-form-control-wrap{
      display: inline-block;
      width: 100%;
      input, textarea, select{
        background-color: white;
        display: inline-block;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #dfdfdf;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 15px;
        &:focus{
          outline:0;
        }
      }
      textarea{
        max-height: 160px;
      }
      .option-selected{
        background-color: $blue;
        color:white;
        option{
          background-color: white;
          color:$text-color;
        }
      }
    }
    span.wpcf7-not-valid-tip{
      position: absolute;
      left: 0;
      bottom: -23px;
    }
  }
  .form-msg{
    margin-top:12px;
    font-size: 10px;
    font-weight: 400;
    color:$gray-third;
  }
  .submit-wrapper{
    cursor: pointer;
    background-color: #003d6b;
    border-radius: 100px;
    display: inline-block;
    position: relative;
    margin-top: 23px;
    input{
      cursor: pointer;
      padding-right: 50px;
      padding-left: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      color:white;
      font-size: 12px;
      font-weight: bold;
      line-height: 1em;
      border: 0;
      background-color: transparent;
      text-transform: uppercase;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      background-image: url('./src/img/arrow_right.svg');
      background-repeat: no-repeat;
      background-position: 95% center;
    }
    &:hover{

    }
  }
  .ajax-loader{
    position: absolute;
    top:50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &.form-contact-map{
    .form-bottom{
      text-align: right;
    }
    .submit-wrapper{
      background-color: $yellow;
      input{
        background-image: url('./src/img/paper_plane.png');
        padding-right: 20px;
        padding-left: 50px;
        background-position: 10% center;
      }
    }
  }
}

.select2-container--default{
  &.option-selected {
    background-color: transparent !important;
    .select2-selection--single{
      background-color: $blue;
      .select2-selection__rendered {
        color: white;
      }
      .select2-selection__arrow {
        b {
          border-color: white transparent transparent transparent;
        }
      }
    }
  }
  .select2-selection--single{
    background-color: white;
    padding: 10px 15px;
    height:auto;
    border: 1px solid #dfdfdf;
    &:focus{
      outline:0;
    }
    .select2-selection__rendered{
      color:$blue;
      line-height: 1.45em;
    }
    .select2-selection__arrow{
      height: 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      width: 18px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      b{
        border-color: $blue transparent transparent transparent;
        border-width: 10px 9px 0 9px;
        margin-left:0;
        margin-top:0;
        top:0;
        left:0;
      }
    }
  }
}
/* Bloc de recherche d'un établissment */
.establishment-seek{
  .establishment-up{
    position: relative;
    z-index: 2;
    .form-wrapper{
      padding:  31px 34px 52px 34px;
      box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.1);
      background-color: white;
      .main-title-blue{
        font-size: 24px;
        font-weight: 500;
        position: relative;
        &:before{
          content: '';
          width: 62px;
          height: 3px;
          background-color: $yellow;
          position: absolute;
          top:0;
          left:0;
        }
      }
      #searchForm{
        margin-top: 37px;
        input[type="text"]{
          width: 790px;
          border: 1px solid #dfdfdf;
          padding: 15px 24px;
          background-image:url('./src/img/picto_search.png');
          background-repeat: no-repeat;
          background-position: 98% center;
          &:focus{
            outline:0;
          }
        }
        input[type="submit"]{
          width: 190px;
          border-radius: 100px;
          background-color: #f9d219;
          padding: 18px 37px 18px 83px;
          font-size: 12px;
          font-weight: 700;
          color:$blue;
          cursor: pointer;
          border:0;
          letter-spacing: 0.33px;
          text-transform: uppercase;
          background-image: url('./src/img/picto_loupe.png');
          background-repeat: no-repeat;
          background-position:37px center;
          margin-left: 28px;
          &:focus{
            outline:0;
          }
        }
      }
    }
    .results{
      .etablishment-found-wrapper{
        width: 790px;
        margin-top: 39px;
        .bloc-title{
          border-radius: 14px 14px 0 0;
          background-color: #dfdfdf;
          font-size: 16px;
          font-weight: 500;
          color:$gray;
          max-width: 360px;
          padding-left: 19px;
          padding-top: 7px;
          padding-bottom: 7px;
          font-weight: 600;
        }
        .bloc-content{
          border-radius: 2px;
          border: 2px solid #dfdfdf;
          padding-left: 19px;
          padding-top: 21px;
          padding-bottom: 21px;
          display: flex;
          align-items: center;
          > div{
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
          }
          .bloc-adress{
            .establishment-title{
              font-size: 18px;
              font-weight: 700;
            }
            .establishment-adress{
              font-size: 16px;
              font-weight: 400;
            }
          }
          .bloc-phone{
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            line-height: 26px;
            text-transform: uppercase;
            color: $blue;
            position: relative;
            > div{
              &:before{
                content: url("./src/img/picto_phone.png");
                display: block;
              }
            }
            span{
              font-size: 18px;
            }
            &:before{
              content: "";
              width: 1px;
              height: 49px;
              background-color: #dcdcdc;
              position: absolute;
              top: 50%;
              left: 0;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
            &:after{
              content: "";
              width: 1px;
              height: 49px;
              background-color: #dcdcdc;
              position: absolute;
              top: 50%;
              right: 0;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
          .bloc-link{
            text-align: center;
          }
        }
      }

    }
  }
  .establishment-bottom{
    margin-top: -50px;
    position: relative;
    overflow: hidden;
    .map-etablissements{
      height: 1000px;
      width:calc(100% - 525px);
    }
    .form-right{
      position: absolute;
      width: 660px;
      right: 0;
      top: -25%;
      bottom: -25%;
      background-color: #fbfbfb;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      padding-left: 150px;
      display: flex;
      align-items: center;
      .form-right-wrapper{
        padding-top: 60px;
        max-width:367px;
        .submit-wrapper{
          input{
            color:$blue;
          }
        }
        .main-title-blue{
          position: relative;
          &:before{
            content: '';
            background-color: $yellow;
            width: 62px;
            height: 3px;
            position: absolute;
            top: -5px;
            left:0;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg){
    .establishment-up{
      .form-wrapper{
        #searchForm{
          input[type="text"]{
            width: 610px;
          }
        }
      }
      .form-search-results{
        ul{
          width: 610px;
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .establishment-up{
      .form-wrapper{
        #searchForm{
          text-align: center;
          input[type="text"]{
            width: 100%;
          }
          input[type="submit"]{
            margin-top: 15px;
            margin-bottom: 15px;
            margin-left: 0;
          }
        }
      }
      .form-search-results{
        ul{
          width: 100%;
        }
      }
      .results{
        .etablishment-found-wrapper{
          width: 100%;
          .bloc-content{
            padding-left: 15px;
            padding-right: 15px;
            flex-direction: column;
            text-align: center;
            > div {
              flex:100%;
              max-width: 100%;
              width:100%;
            }
            .bloc-phone{
              margin-top: 15px;
              margin-bottom: 15px;
              padding-top: 15px;
              padding-bottom: 15px;
              border-top:1px solid #dcdcdc;
              border-bottom:1px solid #dcdcdc;
              &:before{
                display: none;
              }
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
    .establishment-bottom{
      .map-etablissements{
        height: 550px;
        width:100%;
      }
      .form-right{
        position: static;
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        padding: 15px;
        justify-content: center;
      }
    }
  }
  @include media-breakpoint-down(sm){
    .establishment-up{
      .form-wrapper{
        padding: 15px;
        box-shadow: unset;
      }
    }
  }
}
.form-search-results{
  ul{
    width: 790px;
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid #dfdfdf;
    list-style-type: none;
    padding-left: 5px;
    li{
      cursor: pointer;
      color: $blue;
      &:hover{
        background-color: $blue;
        color: white;
      }
    }
  }
  &#zipcode-second{
    ul{
      width: 100%;
    }
  }
  @include media-breakpoint-down(lg){
    ul{
      width: 610px;
    }

    &#zipcode-second{
      ul{
        width: 100%;
      }
    }
  }
  @include media-breakpoint-down(md){
    ul{
      width: 100%;
    }
  }
}

.results-form, .results-form-second{
  margin-top: 41px;
  .form-title{
    font-size: 16px;
    font-weight: 700;
    color:$blue;
  }
  form{

  }
}
.area-unknown-wrapper{
  margin-top: 26px;
  font-size: 16px;
  font-weight: 400;
  color: $gray;
  span{
    display: inline-block;
    border-radius: 100px;
    background-color: #f7f7f7;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 22px;
    margin: 0 10px;
  }
}
/*Bloc bannière*/
#block-banner{
  height: 509px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 125%;
  background-color: rgba(0, 61, 107, 0.9);
  display: flex;
  align-items: center;
  position: relative;
  #breadcrumbs{
    position: absolute;
    top:15px;
    left: calc(50% - 522px)
  }
  .row{
    align-items: center;
  }
  .banner-left{
    color:white;
    .banner-content{
      margin-top: 40px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .banner-right{
    text-align: right;
    img{
      max-width: 100%;
    }
  }
  &.banner-yellow{
    background-color: rgba(249,210,25,0.9);
    overflow: hidden;
    .banner-left{
      color:$blue;
    }
    &:before{
      content:url('./src/img/oval_espace_pro.png');
      width: 1058px;
      height: 1353px;
      position: absolute;
      right:-515px;
      top:104px;
    }
  }
  @media (max-width:1439px){
    #breadcrumbs{
      left:calc(50% - 423px);
    }
  }
  @include media-breakpoint-down(lg){
    background-size: cover;
    background-position-y: center !important;
    #breadcrumbs{
      top:45px;
      left:25px;
    }
  }
  @include media-breakpoint-down(xs){
    .banner-right{
      text-align: center;
      margin-top: 15px;
    }
  }
}

/* Bloc liste de chiffre*/
#list-number{
  background-color: $yellow;
  padding: 40px 0;
  .row{
    .col-list-number{
      &:first-child{
        text-align: left;
      }
      &:nth-child(2){
        text-align: center;
      }
      &:nth-child(3){
        text-align: right;
      }
      .col-list-wrapper{
        text-align: left;
        display: inline-block;
      }
    }
  }
  .number-title{
    color:$blue;
    font-size: 20px;
    font-weight: 700;
    margin-left: -25px;
    line-height: 1.2em;
    margin-top: 27px;
  }
  .number-number{
    color:white;
    font-size: 100px;
    font-weight: 700;
    line-height: 1em;
  }
  .number-text{
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.95px;
    color:$text-color;
  }
  .number-up{
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(md){
    .row{
      .col-list-number{
        &:first-child, &:nth-child(2), &:nth-child(3){
          text-align:left;
        }
      }
    }
  }
}

/* Scroll-down*/
.scroll-down{
  text-align: center;
  height: 39px;
  width: 20px;
  img{
    cursor:pointer;
  }
  &:after{
    content: '';
    background-image: url('./src/img/arrow_white.png');
    background-size: contain;
    display: inline-block;
    width: 8px;
    height: 5px;
    top: -5px;
    position: relative;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
  }
}

@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
@-moz-keyframes  scroll{
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
@-webkit-keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
@-ms-keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}


/* Slider de témoignages */
.slider-testimonial-wrapper{
  padding-top: 100px;
  padding-bottom: 224px;
  background-size: cover;
  .container{
    position: relative;
  }
  .slider-title{
    font-size: 40px;
    font-weight: 400;
    color:$yellow;
    text-align: center;
  }
  .slider-testimonial{
    color:white;
    margin-top: 60px;
    text-align: center;
    overflow: hidden;
    max-width: 600px;
    margin-left:auto;
    margin-right: auto;
    .testimonial-content{
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.22px;
    }
    .testimonial-title{
      margin-top: 36px;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1.22px;
    }
    .testimonial-business{
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.95px;
      margin-top: 10px;
    }
    .swiper-pagination{
      position: static;
      margin-top: 25px;
      .swiper-pagination-bullet{
        background:transparent;
        border:1px solid white;
        opacity: 1;
      }
      .swiper-pagination-bullet-active{
        background-color: $yellow;
        border:1px solid $yellow;
      }
    }
  }
  .testimonial-arrow{
    font-size: 12px;
    font-weight: 400;
    color:white;
    letter-spacing: 0.81px;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    top:45%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:focus{
      outline:0;
    }
    span.arrow{
      display: inline-block;
      width: 30px;
      height: 2px;
      background-color: $yellow;
      vertical-align: middle;
      position: relative;
      &:before, &:after{
        content:"";
        width: 8px;
        height: 2px;
        background-color: $yellow;
        position: absolute;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
    &.testimonial-next{
      right: 0;
      span.arrow{
        margin-left: 20px;
        &:before, &:after{
          right:0;
          -webkit-transform-origin:right;
          -moz-transform-origin:right;
          -ms-transform-origin:right;
          -o-transform-origin:right;
          transform-origin:right;
        }
      }
    }
    &.testimonial-prev{
      left:0;
      span.arrow{
        margin-right: 20px;
        &:before, &:after{
          left:0;
          -webkit-transform-origin:left;
          -moz-transform-origin:left;
          -ms-transform-origin:left;
          -o-transform-origin:left;
          transform-origin:left;
        }
      }
    }
    &:hover{
      color:$yellow;
      &.testimonial-next{
        span.arrow{
          &:before{
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:after{
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
      &.testimonial-prev{
        span.arrow{
          &:before{
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:after{
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .testimonial-arrow{
      display: none;
    }
  }
}
/* Fin slider de témoignages*/


/* Page 404 */
#bloc-404{
  min-height:calc(100vh - 381px);
  padding-top:50px;
  text-align: center;
  .text-content{
    margin-top: 50px;
    a{
      display: inline-block;
      margin-top: 25px;
    }
  }
}
#page-main{
  min-height:calc(100vh - 381px);
  padding-top:50px;
  .main-title-blue{
    text-align: center;
  }
  .text-content{
    margin-top: 50px;
  }
}

/* modal */

#mailing-modal, #brochure-modal{
  .modal-dialog{
    max-width: 748px;
    top:50vh;
    .modal-content{
      align-items: center;
      padding: 26px 90px 30px 90px;
      border: 0;
      border-radius: 10px;
      position: relative;
    }
    .close-modal{
      position: absolute;
      right:15px;
      top:15px;
      cursor: pointer;
    }
    .modal-title{
      color:$blue;
      max-width:320px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 25px;
      text-align:center;
    }
    .modal-form{
      margin-top: 25px;
      width: 100%;
      .form-bottom{
        text-align: center;
        .submit-wrapper{
          input{
            background-image:url('./src/img/arrow_white_down.png');
            background-position: 90% center;
          }
        }
      }
    }
  }
  &.show{
    .modal-dialog{
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  @include media-breakpoint-down(sm){
    .modal-dialog{
      .modal-content{
        padding: 15px;
      }
    }
  }
}


/* Tip fixed */
.phone-fixed{
  cursor:pointer;
  position: fixed;
  width: 250px;
  top:50%;
  right: 0;
  border-radius: 100px 0 0 100px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 24px;
  z-index: 2;
  display: flex;
  align-items: center;
  -webkit-transform: translateX(174px);
  -moz-transform: translateX(174px);
  -ms-transform: translateX(174px);
  -o-transform: translateX(174px);
  transform: translateX(174px);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  img{
    pointer-events:none
  }
  .phone{
    margin-left: 40px;
  }
  &:hover{
    background-color: $yellow;
    color: $blue;
  }
  &.show-phone{
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    background-color: $yellow;
    color:$blue;
  }
}