footer{
  background-color: $blue;
  padding-top:60px;
  .footer-up{
    padding-bottom: 53px;
    .left-col-wrapper{
      .left-col-title{
          color:white;
          font-size: 24px;
          font-weight: 500;
          line-height: 27px;
          font-family:$font-title;
          position: relative;
          &:before{
            content: "";
            position: absolute;
            height: 3px;
            width: 62px;
            background-color:$yellow;
            left:0;
            top:-10px;
        }
      }
      .footer-form{
        .input-wrapper{
          width: 70%;
          display:inline-block;
          vertical-align: middle;
        }
        .form-bottom{
          width: 29%;
          display:inline-block;
          vertical-align: middle;
          .submit-wrapper{
            margin-left: 15px;
            background-color: $yellow;
            input{
              background-image: url('./src/img/paper_plane.png');
              padding-right: 20px;
              padding-left: 50px;
              background-position: 10% center;
              color:$blue;
            }
          }
        }
      }
      .footer-socials{
        margin-top: 15px;
        a{
          color:white;
        }
      }
    }
    .footer-menu{
      padding-left: 15px;
      padding-right: 15px;
      .menu-item{
        a{
          display: inline-block;
          color:white;
          position: relative;
          margin-top: 29px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.33px;
          &:hover{
            text-decoration: none;
            &:before{
              width: 100%;
            }
          }
          &:before{
            content:"";
            width: 14px;
            height: 2px;
            background-color: $yellow;
            top:-5px;
            left:0;
            position: absolute;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;
          }
        }
        &:nth-child(-n+4){
          a{
            margin-top: 0;
          }
        }
      }
    }
  }
  .footer-bottom{
    border-top:1px solid $yellow;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.33px;
    padding: 15px 0;
    color:white;
    a{
      color:white;
      &:hover{
        text-decoration: none;
      }
    }
  }
  @include media-breakpoint-down(md){
    .right-col{
      margin-top: 15px;
    }
  }
  @include media-breakpoint-down(sm){
    .footer-up{
      .footer-menu{
        .menu-item{
          &:nth-child(-n+4){
            a{
              margin-top: 29px;
            }
          }
        }
      }
      .left-col-wrapper{
        .footer-socials{
          text-align: center;
        }
      }
    }
  }
  @include media-breakpoint-down(xs){
    .footer-up{
      .left-col-wrapper{
        .footer-form{
          .input-wrapper{
            width: 100%;
          }
          .form-bottom{
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}