header{
  background-color: white;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  .navbar{
    padding-top: 0;
    padding-bottom: 0;
    .navbar-toggler {
      border: 0;
      &:focus {
        outline: 0;
      }
      .menu-bar {
        width: 30px;
        height: 3px;
        background-color: $blue;
        display: block;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -ms-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &[aria-expanded="true"] {
        .menu-bar {
          transform-origin: 10%;
          &:first-child {
            -webkit-transform: rotate(40deg);
            -moz-transform: rotate(40deg);
            -ms-transform: rotate(40deg);
            -o-transform: rotate(40deg);
            transform: rotate(40deg);
          }
          &:last-child {
            -webkit-transform: rotate(-40deg);
            -moz-transform: rotate(-40deg);
            -ms-transform: rotate(-40deg);
            -o-transform: rotate(-40deg);
            transform: rotate(-40deg);
          }
          &:nth-child(2) {
            transform-origin: top left;
            -webkit-transform: translateX(100%);
            -moz-transform: translateX(100%);
            -ms-transform: translateX(100%);
            -o-transform: translateX(100%);
            transform: translateX(100%);
            opacity: 0;
          }
        }
      }
    }
  }
  .navbar-nav{
    position: relative;
    z-index: 999;
  }
  .navbar-brand{
    padding-bottom: 0;
    padding-top: 5px;
    a{
      width: 111px;
      height: 69px;
      display: inline-block;
      position: relative;
      img{
        max-width: 111px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  #main-navbar{
    justify-content: flex-end;
    align-items: center;
    position: relative;
    #searchform{
      overflow: hidden;
      position: absolute;
      right:210px;
      width: 0;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      top: -23px;
      bottom: -22px;
      display: flex;
      align-items: center;
      background: white;
      z-index: 9999;
      .e{
        width: 100%;
      }
      input[type="text"]{
        border:0;
        border-bottom: 1px solid $gray-second;
        width: 100%;
        &:focus{
          outline:0;
        }
      }
      #searchsubmit{
        display: none;
      }
      &.show-search{
        width: calc(100% - 210px);
        input[type="text"]{
          padding: 5px;
        }
      }
    }
    ul.navbar-nav{
     > li{
        padding: 0 22px;
        &:first-child{
          padding-left: 0px;
        }
        &:last-child{
          padding-right: 0px;
        }
        > a{
          color:$blue;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.39px;
          line-height: 1em;
          display: inline-block;
          text-align: center;
          &:hover, &:focus{
            color: $blue;
            text-decoration: none;
          }
        }
      }
    }
    .navbar-right{
      margin-left: 30px;
      .search{
        display: inline-block;
        font-size: 20px;
        color:$blue;
        cursor:pointer;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        &:hover{
          color:$yellow;
        }
      }
      .btn-contact{
        display: inline-block;
        color:white;
        background-color: $blue;
        border-radius: 100px;
        padding: 8px 23px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.33px;
        margin-left: 30px;
        margin-right: 30px;

        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        &:hover{
          background-color: $yellow;
          text-decoration: none;
        }
      }
      .fb-link{
        display: inline-block;
        font-size: 20px;
        color:$blue;

        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        &:hover{
          color:$yellow;
        }
      }
    }
    .menu-item-has-children{
      position: relative;
      .dropdown-menu{
        top:52px;
        background-color: $blue;
        padding: 20px 10px;
        left:50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        width: auto;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
        border-radius: 0 0 6px 6px;
        min-width:136px;
        border:0;
        white-space: nowrap;
        li{
          margin-bottom: 20px;
          a{
            color:white;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.39px;
            position: relative;
            display: inline-block;
            text-align: left;
            transition: all 300ms ease-in-out;
            &:before {
              //text-align: left;
              //box-sizing: border-box;
              //position: absolute;
              //top: 0;
              //left: 0;
              //overflow: hidden;
              //transition: all 300ms ease-in-out;
              //content: attr(data-hover);
              //height: 0px;
              //color: $yellow;
              //background: $blue;
            }
            &:hover{
              text-decoration: none;
              color: $yellow;
              &:before{
                //height: 100%;
              }
            }
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
        &:before{
          content:"";
          width: 100%;
          height: 31px;
          bottom:100%;
          position: absolute;
          left:0;
        }
      }
      &:hover{
        .dropdown-menu{
          display: block;
        }
        &:after{
          opacity: 1;
          -webkit-transform: translateX(-50%) translateY(5px);
          -moz-transform: translateX(-50%) translateY(5px);
          -ms-transform: translateX(-50%) translateY(5px);
          -o-transform: translateX(-50%) translateY(5px);
          transform: translateX(-50%) translateY(5px);
        }
      }
      &:after{
        content:url('./src/img/arrow_blue_down.png');
        width: 16px;
        height:9px;
        position: absolute;
        bottom:0;
        left:50%;
        z-index: -1;
        opacity: 0;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
    }
  }

  .open-sub{
    display: none;
  }
  @media (min-width: 1200px) and (max-width: 1439px){
    #main-navbar{
      ul.navbar-nav{
        > li{
          padding: 0 10px;
        }
      }
      .navbar-right{
        margin-left: 10px;
        .btn-contact{
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    #main-navbar{
      ul.navbar-nav{
       > li{
          display: flex;
          > a{
            position: relative;
            display: flex;
            align-items: center;
            span{
              -webkit-transition: all 0.2s ease-in-out;
              -moz-transition: all 0.2s ease-in-out;
              -ms-transition: all 0.2s ease-in-out;
              -o-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out;
            }
            &:after{
              content: "";
              position: absolute;
              left:0;
              right: 0;
              bottom:100%;
              width:100%;
              height: 2px;
              background-color: $blue;
              -webkit-transform: scale3d(0.1,1,1);
              -moz-transform: scale3d(0.1,1,1);
              -ms-transform: scale3d(0.1,1,1);
              -o-transform: scale3d(0.1,1,1);
              transform: scale3d(0.1,1,1);
              -webkit-transition: all 0.2s ease-in-out;
              -moz-transition: all 0.2s ease-in-out;
              -ms-transition: all 0.2s ease-in-out;
              -o-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out;
              opacity: 0;
              z-index: -1;
            }

          }
          &.current-menu-item, &.current-menu-ancestor{
            a{
              &:after{
                transform: scale3d(1, 1, 1);
                bottom: 54px;
                opacity: 1;
              }
              span{
                -webkit-transform: translateY(-10px) scale(1.1);
                -moz-transform: translateY(-10px) scale(1.1);
                -ms-transform: translateY(-10px) scale(1.1);
                -o-transform: translateY(-10px) scale(1.1);
                transform: translateY(-10px) scale(1.1);
              }
            }
          }
         &:hover{
           > a {
               span{
                 -webkit-transform: translateY(-10px);
                 -moz-transform: translateY(-10px);
                 -ms-transform: translateY(-10px);
                 -o-transform: translateY(-10px);
                 transform: translateY(-10px);
               }
               &:after{
                 bottom:54px;
                 opacity: 1;
                 -webkit-transform: scale(1);
                 -moz-transform: scale(1);
                 -ms-transform: scale(1);
                 -o-transform: scale(1);
                 transform: scale(1);
               }

           }
         }
        }
      }

    }
  }
  @include media-breakpoint-down(lg){
    #main-navbar{
      padding-top: 20px;

      max-height: calc(100vh - 81px);
      overflow: scroll;
      ul.navbar-nav{
        li{
          padding: 0px 0px;
          background-color: $blue;
          a{
            color:white;
            padding: 15px 0;
            font-size: 20px;
            br{
              display: none;
            }
          }
        }
      }
      .menu-item-has-children{
        .dropdown-menu{
          position: static;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none;
          box-shadow: none;
          border-radius: 0;
          padding: 5px 10px;
          padding-left: 35px;
          display: block;
          width: 100%;
          white-space:unset;
          background-color: white;
          li{
            margin-bottom: 10px;
            background-color: white;
            a{
              color:$blue;
              padding:5px 0;
              &:before{
                display: none;
              }
            }
          }
          &:before{
            display: none;
          }
        }
        &:after{
          display: none;
        }
      }
      .navbar-right{
        margin-left: 0;
        text-align: center;
        margin-top: 15px;
        padding-bottom: 50px;
      }
      #searchform{
        position: relative;
        right:0;
        width: 100%;
        height: 0;
        overflow: hidden;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        top:auto;
        bottom:auto;
        &.show-search{
          height: 35px;
          width: 100%;
          input[type="text"]{
            padding: 5px;
          }
        }
      }
    }
    .open-sub{
      display: block;
    }
  }
  @include media-breakpoint-down(md){
    .navbar-brand{
      a{
        img{
          max-width: 90px;
        }
      }
    }
  }
}