#single-etablissement-intro{
  height: calc(100vh - 81px);
  background-size: cover;
  position: relative;
  overflow: hidden;
  #breadcrumbs{
    position: absolute;
    top:15px;
    left: calc(50% - 522px);
    z-index:10;
  }
  .container-fluid{
    display:flex;
    height: 100%;
    align-items: center;
    position: relative;
    z-index: 2;
    .row{
      width: 100%;
      justify-content: flex-end;
    }
  }
  .etab-wrapper{
    //max-width: 380px;
    color:white;
    text-align:center;
  }
  .main-title-white{
    font-family: $font-main;
    text-transform: none;
  }
  .etab-content{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 20px;
    text-align: center;
    &:after{
      margin-top: 29px;
      width: 37px;
      height: 2px;
      content:"";
      background-color: $yellow;
      display: inline-block;
    }
  }
  .etab-address, .etab-phone, .etab-mail, .etab-setence{
    font-family: $font-title;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin-top: 26px;
  }
  .etab-phone{
    &:before{
      content:"";
      width: 20px;
      height: 20px;
      display: inline-block;
      background-image: url('./src/img/picto_phone_yellow.png');
      background-size: cover;
      margin-right: 15px;
      vertical-align: middle;
    }
  }
  .etab-mail{
    &:before{
      content:"";
      width: 20px;
      height: 14px;
      display: inline-block;
      background-image: url('./src/img/picto_mail_yellow.png');
      background-size: cover;
      margin-right: 15px;
      vertical-align: middle;
    }
  }
  &:after{
    z-index: 1;
    content: "";
    position: absolute;
    right: 0;
    width: 45%;
    background-image: url('./src/img/oval_blue.png');
    background-size: cover;
    top:0;
    height: 100%;
    bottom:0;
  }
  &:before{
    z-index: 2;
    content: "";
    position: absolute;
    right: 45%;
    width: 62px;
    background-image: url('./src/img/picto_map.png');
    background-size: cover;
    top:50%;
    height: 78px;
    -webkit-transform: translateY(-50%) translateX(45%);
    -moz-transform: translateY(-50%) translateX(45%);
    -ms-transform: translateY(-50%) translateX(45%);
    -o-transform: translateY(-50%) translateX(45%);
    transform: translateY(-50%) translateX(45%);
  }
  .scroll-down{
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 25px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
  }
  @media (max-width:1439px){
    #breadcrumbs{
      left:calc(50% - 423px);
    }
  }
  @include media-breakpoint-down(lg){
    #breadcrumbs{
      top:45px;
      left:25px;
    }
  }
  @include media-breakpoint-down(sm){
    height: auto;
    min-height: calc(100vh - 81px);
    padding-bottom: 10px;
    .container-fluid{
      .row{
        margin: 0;
      }
    }
    .etab-wrapper{
      margin: 0 auto;
      margin-top:90px;
    }
    .etab-content{
      font-size: 14px;
      p{
        margin-bottom: 5px;
      }
      &:after{
        margin-top: 0px;
      }
    }
    .etab-address{
      margin-top: 5px;
      font-size:14px;
    }
    .etab-phone{
      margin-top: 5px;
      font-size: 14px;
    }
    .etab-mail{
      margin-top: 5px;
      font-size: 14px;
    }
    &:before{
      display: none;
    }
    &:after{
      width: 200%;
      height: 200%;
    }
  }
}
#single-etab-chef{
  padding-top: 75px;
  .row-intro{
    text-align: center;
    .chef-intro-content{
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1.08px;
    }
  }
  .row-chef{
    border-radius: 0 6px 6px 0;
    margin-top:75px;
    align-items: center;
    position: relative;
    z-index:1;
    background-color: white;
    .chef-img{
      img{
        width: 100%;
        height: auto;
      }
    }
    .chef-content-col{
      display: flex;
      align-items: center;
      padding-left: 50px;
      padding-right: 50px;
      .chef-title{
        color:$blue;
        font-size: 40px;
        font-weight: 400;
      }
      .chef-content{
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1.08px;
        margin-top: 32px;
      }
      .chef-name{
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        font-weight: 500;
        color:$blue;
        font-family: $font-title;
      }
      .chef-status{
        font-size: 16px;
        font-weight: 400;
        color:$blue;
      }
    }
  }
  @include media-breakpoint-down(sm){
    .row-chef{
      .chef-content-col{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
#single-etab-formules{
  background-color: $yellow;
  background-image: url('./src/img/bg_liste_offres.png');
  background-size: contain;
  background-position-y: 75%;
  padding-top: 209px;
  margin-top: -135px;
  padding-bottom: 58px;
  background-repeat: no-repeat;
  .main-title-blue{
    text-align: center;
  }
  .swiper-container{
    .slide-formule-single{
      background-color: white;
      height: auto;
      padding: 30px 50px;
      font-family: $font-title;
      text-align: center;
      .formule-title{
        color:$blue;
        font-size: 14px;
        font-weight: 600;
      }
      .formule-reducs{
        margin-top: 17px;
      }
      .formule-ttc{
        border:2px solid white;
        vertical-align: middle;
        margin-right: 13px;
        display: inline-flex;
        justify-content: center;
        width: 90px;
        height: 90px;
        border-radius: 100%;
        color:white;
        background-color: $blue;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        flex-flow: column;
        .price{
          display: block;
          font-size:20px;
          line-height: 1em;
          font-weight: 500;
        }
      }
      .formule-ht{
        border:2px solid white;
        vertical-align: middle;
        margin-left: 13px;
        display: inline-flex;
        justify-content: center;
        width: 90px;
        height: 90px;
        border-radius: 100%;
        color:$blue;
        background-color: $yellow;
        font-size: 14px;
        font-weight: 500;
        flex-flow: column;
        font-size: 10px;
        font-weight: 500;
        .price{
          display: block;
          font-size: 24px;
          font-weight: 500;
          line-height: 1em;
        }
      }
      .formule-content{
        border: 2px solid #f9d219;
        padding-top:75px;
        margin-top:-45px;
      }
      .formule-food{
        .formule-food-type{
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          color:$blue;
          position: relative;
          padding-bottom: 10px;
          &:after{
            content:"";
            width: 23px;
            height: 2px;
            margin-top: 10px;
            display: inline-block;
            background-color: $yellow;
            position: absolute;
            bottom:0;
            left:50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
        .formule-food-food{
          margin-top:8px;
          font-size: 16px;
          font-weight: 500;
          color:$gray;
        }
      }
      .formule-infos{
        font-size: 12px;
        font-weight: 500;
        color: #9b9b9b;
        margin-top: 38px;
      }
      .btn{
        margin-top: 30px;
      }
    }

  }
  .swiper-pagination{
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    span.swiper-pagination-bullet{
      border-radius: 5px;
      margin-top: 22px;
      width: auto;
      height: auto;
      background-color: transparent;
      opacity:1;
      color:white;
      text-transform: uppercase;
      padding: 7px 13px;
      font-size:14px;
      font-weight: 600;
      -webkit-transition: all 0.1s;
      -moz-transition: all 0.1s;
      -ms-transition: all 0.1s;
      -o-transition: all 0.1s;
      transition: all 0.1s;
     &:hover, &.swiper-pagination-bullet-active{
        background-color: white;
        color:$blue;
      }
    }
  }
  .slider-gourmets, .slider-medical{

  }
  .formules-btns-wrapper{
    max-width: 555px;
    margin:0 auto;
    margin-top: 50px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    .formule-btn{
      padding: 14px 5px;
      text-align: center;
      background-color: white;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
      color:$blue;
      border-radius: 4px;
      text-transform: uppercase;
      flex:1 1 45%;
      max-width: 45%;
      font-family: $font-title;
      font-weight:600;
      cursor: pointer;
      opacity: 0.4;
      -webkit-transition: all 0.1s;
      -moz-transition: all 0.1s;
      -ms-transition: all 0.1s;
      -o-transition: all 0.1s;
      transition: all 0.1s;
      &.shown, &:hover{
        opacity: 1;
      }
    }
  }
  .slider-gourmet-container, .slider-medical-container{
    position:relative;
    max-width: 555px;
    margin: 0 auto;
    display: none;
    &.shown{
      display: block;
    }
    .slider-arrow{
      cursor: pointer;
      z-index:2;
      position: absolute;
      top:45%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      &.prev-arrow{
        left:-20px;

      }
      &.next-arrow{
        right:-20px;
      }
      &:focus{
        outline:0;
      }
    }
  }

  @include media-breakpoint-down(sm){
    background-image:none;
    .slider-arrow{
      display: none;
    }
    .swiper-container{
      .slide-formule-single{
        padding: 10px;
      }
    }
  }
}
#single-etab-offer{
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  .offer-title{
    color:$blue;
    font-size: 40px;
    font-weight: 400;
  }
  .offer-subhead{
    color:$blue;
    font-size: 30px;
    font-weight: 700;
  }
  .offer-content{
    margin-top:20px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.08px;
  }
  .btn{
    margin-top: 28px;
  }
}
#offer-tarifs{
  padding-top: 60px;
  padding-bottom: 60px;
  .main-title-blue{
    text-align: center;
  }
  .tarifs-blocs{
    justify-content: center;
    margin-top: 60px;
  }
  .col-tarif-content{
    height: 100%;
    text-align: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 1px solid #fbfbfb;
    background-color: #ffffff;
    padding-top: 21px;
    padding-bottom: 30px;
    .tarif-top{
      min-height: 60px;
    }
    .tarif-single-title{
      color: $yellow;
      font-size: 50px;
      line-height: 1em;
      font-weight: 700;
      margin-top: 15px;
    }
    .tarif-bloc-title{
      color:$blue;
      text-transform:uppercase;
      font-size: 18px;
      font-weight: 700;
      font-family: $font-title;
      &:after{
        content:"";
        background-color: $yellow;
        display: block;
        width: 34px;
        height:3px;
        margin:0 auto;
        margin-top: 15px;
      }
    }
    .tarif-content{
      max-width: 90%;
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1.08px;
      margin: 0 auto;
      margin-top: 15px;
    }
  }
}
#single-etab-map{
  margin-top: 70px;
}