#espace-pro-content{
  background-image:url('./src/img/espace_pro/espace_pro.png');
  background-position-x: right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding-top: 74px;
  padding-bottom: 67px;
  .text-content{
    margin-top: 25px;
  }
  @include media-breakpoint-down(md){
    background:none;
  }
}
#espace-pro-etablissements{
  margin-top: -172px;
}
.page-id-404{
  #espace-pro-content{
    background-image:url('./src/img/espace_pro/photo1-hebergements-et-accueil-de-jour.png');
    @include media-breakpoint-down(md){
      background:none;
    }
  }
}
.page-id-423{
  #espace-pro-content{
    background-image:url('./src/img/espace_pro/photo4-professionnels-de-sante.png');
    @include media-breakpoint-down(md){
      background:none;
    }
  }
}
.page-id-422{
  #espace-pro-content{
    background-image:url('./src/img/espace_pro/photo2-collectivites-et-assos.png');
    @media (max-width: 1475px){
      background-position-x: calc(100% + 200px);
    }
    @include media-breakpoint-down(lg){
      background-position-x: calc(100% + 300px);
    }
    @include media-breakpoint-down(md){
      background:none;
    }
  }
}
.page-id-424{
  #espace-pro-content{
    background-image:url('./src/img/espace_pro/photo3-autres-partenaires.png');
    @include media-breakpoint-down(md){
      background:none;
    }
  }
}