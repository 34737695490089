#offers-banner{
  height: 545px;
  background-color: $blue;
  position: relative;
  display: flex;
  align-items: center;
  background-image:url('./src/img/offers_banner_oval.png');
  background-position:bottom right;
  background-size:599px 545px;
  background-repeat:no-repeat;
  #breadcrumbs{
    position: absolute;
    top:15px;
    left: calc(50% - 522px)
  }
  .offers-banner-left{
    .banner-content{
      color:white;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 29px;
    }
  }
  .offers-banner-img{
    position: absolute;
    right: -150px;
    top: 5px
  }
  @media (max-width:1439px){
    #breadcrumbs{
      left:calc(50% - 423px);
    }
  }
  @include media-breakpoint-down(lg){
    #breadcrumbs{
      top:45px;
      left:25px;
    }
  }
  @include media-breakpoint-down(md){
    .offers-banner-img{
      width: 80%;
      right:-180px;
      top:50px;
    }
  }
  @include media-breakpoint-down(sm){
    background-image: none;
    .offers-banner-img{
      display: none;
    }
  }
}
#offers-explanation{
  text-align: center;
  padding:99px 0;
  background-size: cover;
  .offers-explanation-content{
    margin-top: 25px;
    color:$gray;
  }
}
#offers-formules{
  background-size: cover;
  padding-bottom: 246px;
  .formules-wrapper{
    max-width:943px;
    margin: 0 auto;
    .formules-btns-wrapper{
      position: relative;
      top:-20px;
      .nav-tabs{
        border-bottom:none;
        justify-content: space-between;
        .nav-item{
          margin-bottom: 15px;
        }
        .nav-link{
          box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
          font-size: 14px;
          font-weight: 500;
          font-family: $font-title;
          color:$blue;
          border-radius: 4px;
          background-color: white;
          padding: 11px 35px;
          border:0;
          position: relative;
          overflow: hidden;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          span{
            z-index: 2;
          }
          //&:before{
          //  position: absolute;
          //  top: 0;
          //  z-index:1;
          //  right: calc(100% + 30px);
          //  width: 100%;
          //  height: 100%;
          //  background: rgba(0,61,107,0.2);
          //  content: '';
          //  -webkit-transition: all 0.3s;
          //  transition: all 0.3s;
          //  -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
          //  transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
          //}
          &:hover{

            box-shadow: 0 0px 20px rgba(0, 0, 0, 0.5);
            //&:before{
            //  right:calc(-100% - 30px);
            //  -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
            //  transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,140deg) translate3d(0,0,0);
            //}
          }
          &.active{
            color:white;
            background-color: $blue;
            &:before{
              background: rgba(255,255,255,0.2);
            }
          }
        }
      }
    }
    .formules-select-wrapper{
      display: none;
      .tab-select{
        width: 100%;
        padding: 15px;
        position: relative;
        top: -20px;
      }
      .select2-container{
        top: -20px;
      }
    }
    .formules-content{
      margin-top: 80px;
      .tab-pane{
        border-radius: 6px;
        background-color: #ffffff;
        padding: 63px 80px;
        font-size: 16px;
        font-weight: 400;
        color:$gray;
        letter-spacing: 1.08px;
        line-height: 1.2em;
        .formule-title{
          margin-bottom: 40px;
        }
        ul{
          padding-left: 0;
          li{
            padding-left: 30px;
            position: relative;
            list-style-type: none;
            margin-bottom: 10px;
            &:before{
              content:url('./src/img/arrow-liste.png');
              position: absolute;
              top:66%;
              left:0;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .formules-wrapper{
      .formules-btns-wrapper{
        display: none;
      }
      .formules-select-wrapper{
        display: block;
      }
    }
  }
  @include media-breakpoint-down(sm){
    .formules-wrapper{
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      .formules-btns-wrapper{
        .nav-tabs{
          .nav-item{
            padding: 0 5px;
            flex:1 1 50%;
            max-width: 50%;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xs){
    .formules-wrapper{
      .formules-btns-wrapper{
        .nav-tabs{
          .nav-item{
            padding: 0 5px;
            flex:1 1 100%;
            max-width: 100%;
            margin-bottom: 5px;
          }
        }
      }
      .formules-content{
        .tab-pane{
          padding: 15px;
        }
      }
    }
  }
}
#offers-etablissements{
  margin-top:-172px;
}




/* Offers - Help and Pricing*/
.page-template-page-help-pricing{
  #block-banner{
    position: relative;
    overflow: hidden;
    &:after{
      content:url('./src/img/oval.png');
      position: absolute;
      right: -659px;
      bottom: -157%;
    }
  }
  .row{
    align-items: center;
  }
  @include media-breakpoint-down(lg){
    #block-banner {
      &:after {
        display: none;
      }
    }
  }
}
#help-credit{
  background-image: url('./src/img/bg_credit.png');
  background-position: top right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding-top: 101px;
  padding-bottom: 102px;
  position: relative;
  background-color:#fbfbfb;
  background-position-x: calc(100% + 67px);
  .help-img{
    position: absolute;
    top:50%;
    right:155px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .help-credit-wrapper{
    .help-credit-text{
      margin-top: 32px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1.08px;
    }
  }
  @include media-breakpoint-down(lg){
    background-image: none;
  }
  @include media-breakpoint-down(md){
    .help-img{
      position: static;
      display: block;
      margin:0 auto;
      margin-top:55px;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
    }
  }
}
#help-helps{
  background-size: cover;
  padding-top:120px ;
  padding-bottom: 145px;
  .helps-content{
    border-radius: 6px;
    max-width: 943px;
    margin: 0 auto;
    background-color:rgba(255,255,255,0.85);
    padding: 100px;
    .helps-text{
      margin-top: 32px;
      color:$gray;
    }
  }
  @include media-breakpoint-down(lg){
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-down(md){
    .helps-content{
      padding: 25px;
    }
  }
}
#help-tarif{
  padding-top: 80px;
  text-align: center;
  padding-bottom: 80px;
  .help-tarif-content{
    margin-top:24px;
    font-size: 16px;
    font-weight: 300;
    color:$gray;
    letter-spacing: 1.08px;
  }
}